import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { vw, patterns, icons } from "../utilities/helpers"
import SEO from "../utilities/seo"

import Block from "../components/block"
import Body from "../components/wrap"
import Bit from "../components/bit"
import Glow from "../components/glow"
import Submenu from "../components/submenu"
import Slice from "../components/slice"
import Meta from "../components/meta"
import Parallax from "../components/parallax"
import Footer from "../components/footer"

const Wrap = styled.section`
  padding-top: ${vw(3)};
`

function sanitizeString(str) {
  str = str.replace(/[^a-z0-9áéíóúñü,_-]/gim, "")
  return str.trim()
}

function renderSlices(content, index) {
  var node = content.primary

  var title = node.section_title.text
  var image = node.section_image.url
  var html = node.section_content.html
  var video = ""
  var link = ""
  var icon = ""

  if (node.vid !== null) {
    if (node.vid.document) {
      video = node.vid.document["data"].link.embed_url
    }
    icon = icons.play
  }

  if (node.report !== null) {
    if (node.report.document) {
      link = node.report.document["data"].link.url
    }
  }

  var id = "m" + sanitizeString(node.menu_label.text).replace(/\s/g, "")

  return (
    <section id={id} key={index}>
      <Slice
        size="medium"
        length={html.length}
        bg={patterns.green}
        fg={image}
        video={video}
        title={title}
        to={link}
        icon={icon}
        label="View Report"
      >
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Slice>
    </section>
  )
}

export default ({ data }) => {
  const menu = []
  const menuItems = data.allPrismicPage.edges[0].node.data.body1

  menuItems.forEach(function (ele, index) {
    if (ele.primary) {
      const item = ele.primary

      var label = item.menu_label.text
      var id = sanitizeString(label).replace(/\s/g, "")

      menu[index] = {
        key: index,
        label: label,
        anchor: "#m" + id,
      }
    }
  })

  return (
    <>
      <main>
        <SEO
          description={data.allPrismicPage.edges[0].node.data.preface.text}
          title="IGD Archives"
          image={data.allPrismicPage.edges[0].node.data.image.url}
        />

        <Submenu items={menu} className="is-visible" />

        <Block>
          <Glow cool top="-5%" left={vw(-4)} delay="0" />
          <Bit ring top="5%" left="15%" />

          <Glow warm top="15%" right={vw(-6)} delay="0.75" />
          <Bit cross top="15%" right="15%" />

          <Glow royal top="50%" left={vw(-6)} delay="0" />
          <Bit cross top="50%" left="15%" />

          <Glow royal bottom="-5%" right={vw(-4)} delay="0.75" />
          <Bit ring top="100%" right="15%" />

          <Body className="page-content">
            <Wrap>
              {data.allPrismicPage.edges[0].node.data.body1.map(renderSlices)}
            </Wrap>
          </Body>
        </Block>

        <Parallax a />
      </main>
      <Footer />
    </>
  )
}

export const query = graphql`
  query archiveQuery {
    allPrismicPage(filter: { uid: { eq: "archive" } }) {
      edges {
        node {
          data {
            preface {
              text
            }
            image {
              url
            }
            body1 {
              ... on PrismicPageDataBody1Section1 {
                primary {
                  menu_label {
                    text
                  }
                  report {
                    document {
                      ... on PrismicDocument {
                        data {
                          link {
                            url
                          }
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                  vid {
                    document {
                      ... on PrismicVideo {
                        data {
                          title {
                            text
                          }
                          link {
                            thumbnail_url
                            embed_url
                          }
                        }
                      }
                    }
                  }
                  section_image {
                    url
                  }
                  section_content {
                    html
                  }
                  section_title {
                    text
                  }
                }
              }
            }
          }
          uid
        }
      }
    }
  }
`
